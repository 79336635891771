.parent-overview {
    display: flex;
    justify-content: center;
    flex-flow: column;
    flex-wrap: wrap;
}

.description {
    font-size: 1.1em;

    @media (max-width: 700px){
        font-size: 0.9em;
    }
}

.skills {
    flex-flow: column;
    margin: 1em 0 1em 0;
}

.skills p {
    font-size: 1.5em;
}

h2 {
    margin-bottom: 0;
    text-decoration: underline #DB3342;
}