.projects-parent {
    margin-bottom: 1em;
}

h3 {
    font-size: 1.55em;
    margin-bottom: 0.2em;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: #DB3342 underline;
}

.project p {
    font-size: 1.45em;
}