.job {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 1em 0 2em 0;
}

.job-dates {
    flex: 25% 1;
    @media (max-width: 800px){
        flex: 100% 1;
    }
}

.job-details {
    @media (max-width: 800px){
        flex: 100% 1;
    }
    flex: 75% 1
}

.resume-container h3 {
    margin: 0 0 0.5em 0;
}

.resume-container h4 {
    margin: 0.2em 0 0.2em 0;
    font-size: 1.5em;
}

p {
    margin: 0;
    font-size: 1.5em;
}