.App {
  margin: 2vw 15vw 2vw 15vw;
  display: flex;
  flex-flow: column;
  justify-content: center;
  font-family: "Poppins", sans-serif;
}

h1 {
  font-size: 3em;
}

h2 {
  font-size: 2.25em;
}

a {
  color: #000;
}

a:hover {
  color: #DB3342;
}

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}
.parent {
    margin: 3em;
}

@media (max-width: 700px) {
    h1 {
        font-size: 2.2em;
    }
}

.introduction {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.icons {
    display: flex;
    font-size: 3em;
    align-items: center;
    justify-content: space-around;
}

img {
    border-radius: 50px;
    margin-right: 2em;
    max-width: 100%;
    height: auto;
}

.name {
    align-content: center;
}

.parent-overview {
    display: flex;
    justify-content: center;
    flex-flow: column;
    flex-wrap: wrap;
}

.description {
    font-size: 1.1em;

    @media (max-width: 700px){
        font-size: 0.9em;
    }
}

.skills {
    flex-flow: column;
    margin: 1em 0 1em 0;
}

.skills p {
    font-size: 1.5em;
}

h2 {
    margin-bottom: 0;
    -webkit-text-decoration: underline #DB3342;
            text-decoration: underline #DB3342;
}
.decoration {
    display: flex;
    justify-content: space-evenly;
    color: #DB3342;
    margin: 1em;
}
.projects-parent {
    margin-bottom: 1em;
}

h3 {
    font-size: 1.55em;
    margin-bottom: 0.2em;
}

a {
    text-decoration: none;
}

a:hover {
    -webkit-text-decoration: #DB3342 underline;
            text-decoration: #DB3342 underline;
}

.project p {
    font-size: 1.45em;
}
.job {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 1em 0 2em 0;
}

.job-dates {
    flex: 25% 1;
    @media (max-width: 800px){
        flex: 100% 1;
    }
}

.job-details {
    @media (max-width: 800px){
        flex: 100% 1;
    }
    flex: 75% 1
}

.resume-container h3 {
    margin: 0 0 0.5em 0;
}

.resume-container h4 {
    margin: 0.2em 0 0.2em 0;
    font-size: 1.5em;
}

p {
    margin: 0;
    font-size: 1.5em;
}
