.parent {
    margin: 3em;
}

@media (max-width: 700px) {
    h1 {
        font-size: 2.2em;
    }
}

.introduction {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.icons {
    display: flex;
    font-size: 3em;
    align-items: center;
    justify-content: space-around;
}

img {
    border-radius: 50px;
    margin-right: 2em;
    max-width: 100%;
    height: auto;
}

.name {
    align-content: center;
}
